import styled from 'styled-components';
import { Link } from 'react-router-dom';
import heroBackground from '../../assets/heroBackground.jpg'
import blobscene from '../../assets/blobscene.png'
import topblob from '../../assets/topblob.png'

export const Container = styled.div`
  min-height: 90vh;
  margin: 0px;
  width: 100%;
  background: linear-gradient(black, #202020);
`

export const Hero = styled.div`
  width: 100vw;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: start;
  background-image: url(${heroBackground});
  background-repeat: no-repeat;
  background-size: cover;
  overflow: none;

  /* &:after {
    content: '';
    position: absolute;
    bottom: 15%;
    left: -5%;
    right: -5%;
    height: 60%;
    background-color: rgba(0, 0, 0, 0.2);
    transform: skewY(12deg);
  } */

  div {
    margin: 20px 0px;
    max-width: 250px;
    height: auto;
    flex-wrap: wrap;
    margin-left: 4rem;
  }

  span {
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
    color: white;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 800;
    font-size : clamp(2rem, 10vw, 3rem);
    padding: 5px 30px;
    padding-bottom: 10px;
    border-radius: 10px;
    padding-top: 5px;
    width: auto;
    box-decoration-break: clone;
    font-family: 'Maven Pro', sans-serif;
  }
`

export const SpecialSpan = styled.span`
  color: lightseagreen;
`

export const InfoContainer = styled.div`
  min-height: 80vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-image: url(${blobscene});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0px;
`

export const InfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  margin-top: 40px auto;
  margin-bottom: 40px;
  color: white;
  font-family: 'Fira Sans', sans-serif;
  
  h3 {
    margin-top: 15px;
  }

  @media (max-width: 500px) {
    width: 80%;
  }
`

export const InfoIconContainer = styled.div`
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'Maven Pro', sans-serif;

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: space-evenly;
  }
`

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  text-align: center;
  width: 200px;
  min-height: 240px;
  margin: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;

  h1 {
    color: black;
  }

  img {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 50px;
    height: 50px;
    background-color: transparent;
    color: white;
  }
`

export const FirstCourseWriting = styled.div`
  position: relative;
  left: -240px;
  top: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: yellow;
  font-family: 'Shadows Into Light', cursive;

  img {
    height: 50px;
    width: 50px;
  }
  
  @media (max-width: 500px) {
    top: -10px;
    left: 0;
    -webkit-transform: rotate(-10deg); 
    -moz-transform: rotate(-10deg); 

    img {
      display: none;
    }
  }
`

export const CardContainer = styled.div`
  min-height: 80vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-family: 'Fira Sans', sans-serif;
`
export const Card = styled.div`
  max-width: 330px;
  min-height: fit-content;
  display: flex;
  padding: 20px;
  flex-direction: column; 
  border-radius: 15px;
  margin: 20px;
  align-items: center;
  justify-content: space-between;
  transition: 150ms ease-in-out;
`

export const CardH2 = styled.h2`
  color: white;
  margin-bottom: 10px;
  text-align: center;
`

export const CardImg = styled.img`
  width: 100%;
  border-radius: 10px;
`

export const CardInfoContainer = styled.div`
  width: 100%;
  color: white;
  margin-top: 20px;
`

export const CardBtn = styled(Link)`
  display: block;
  text-align: center;
  padding: 10px 40px;
  font-size: 20px;
  border-radius: 10px;
  border: 1px solid lightgreen;
  background-color: transparent;
  text-decoration: none;
  color: lightgreen;
  margin-top: 20px;
  cursor: pointer;
  transition: ease-in-out 150ms;
  
  :hover{
    background-color: lightgreen;
    color: black;
  }

  @media (max-width: 500px) {
    border: 1px solid darkblue;
    color: darkblue;

    :hover {
      background-color: darkblue;
      color: lightgreen;
    }
  }
`

export const CreateAccountLink = styled(Link)`
  margin: 50px auto;
  padding: 20px 40px;
  border: 2px solid lightgreen;
  background-color: transparent;
  text-decoration: none;
  border-radius: 10px;
  color: lightgreen;
  transition: 150ms ease-in-out;
  font-size: 25px;

  :hover {
    background-color: lightgreen;
    color: black;
  }
`

export const UnorderedList = styled.ul`
  list-style: none;
  margin: 5px auto;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ListItem = styled.li`
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
`

export const SeeCourseLink = styled(Link)`
  display: inline-block;
  background-color: rgb(255, 99, 71, 0.85);
  text-align: center;
  color: black;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 800;
  font-size : clamp(2rem, 10vw, 3rem);
  padding: 5px 30px;
  padding-bottom: 10px;
  border-radius: 10px;
  padding-top: 5px;
  width: auto;
  text-decoration: none;
  box-decoration-break: clone;
  font-family: 'Maven Pro', sans-serif;
  animation: pulse-orange 2s infinite;

  @keyframes pulse-orange {
  0% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgb(255, 99, 71, 0.85);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
  }
  
  100% {
    transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
  }
}
`

export const LeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const LeadForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const LeadLabel = styled.h2`
  color: white;
  margin: 10px 0px;
`

export const LeadInput = styled.input`
  padding: 10px 20px;
  border: none;
  outline: none;
  margin-bottom: 10px;
  border-radius: 10px;
`

export const LeadBtn = styled.button`
  width: 180px; 
  padding: 10px 20px;
  border: 1px solid rgb(255, 99, 71, 0.85);
  border-radius: 10px;
  cursor: pointer;
  background-color: rgb(255, 99, 71, 0.85);
  transition: 150ms ease-in-out;

  :hover {
    background-color: rgb(255, 99, 71)
  }
`