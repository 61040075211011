import React, { useEffect} from 'react'
import { Container,CardContainer, Card, CardInfo, CardBtn, LoginBtn } from '../styles/components/ProductDisplay.styles'
import { getUser } from '../api/auth';
import { checkout } from '../api/stripe';

const ProductDisplay = ({ userValid, setUser }) => {
  useEffect(() => {
    let id = window.localStorage.getItem("user")
    
    const fetchUser = async (id) => {
      try {
        const query = new URLSearchParams(window.location.search);

        if (query.get('canceled')) {
          let response = await getUser(id)
          setUser(response.data.user)
        }
      
      } catch(error) {
        console.log(error)
      }
    }

    fetchUser(id)
  }, [])

  const handleCheckout = async (priceId) => {
    let response = await checkout(priceId)
    const url = response.data.url
    if (url) {
      window.location.replace(url)
    } else {
      console.log("something wrong")
    }
  }

  return (
    <Container>
      {!userValid ? <LoginBtn to="/register">Login or Register to proceed</LoginBtn> : null}
    <CardContainer
     className="product">
      <Card>
        <CardInfo>
          <h2>Monthly plan</h2>
          <h2>$20.00 USD / month</h2>
          {userValid ? <CardBtn 
            onClick={() => handleCheckout('price_1N9XY3EjhLUeNJAcolFJdbIf')} 
          >Subscribe</CardBtn> 
          : null}
        </CardInfo>
      </Card>
    </CardContainer>
  </Container>
  )
}

export default ProductDisplay