import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  width: 100%;
  height: auto;
  min-height: 90vh;
  background: linear-gradient(black, #202020);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  color: white;
`

export const CardContainer = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 50px auto;
`

export const Card = styled.div`
  border: 3px solid rgba(255, 255, 255, 0.1);
  min-width: 250px;
  padding: 10px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  margin: 10px;
  border-radius: 10px;
  transition: 300ms ease-in-out;

  :hover {
    border: 3px solid lightgreen;
  }
`

export const CardInfo = styled.div`
  height: 200px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`

export const CardBtn = styled.button`
  border: 1px solid lightgreen;
  background-color: transparent;
  color: lightgreen;
  padding: 10px 70px;
  transition: 150ms ease-in-out;
  cursor: pointer;
  border-radius: 5px;
  transition: 150ms ease-in-out;
  margin-top: auto;
  font-size: 18px;

  :hover {
    color: black;
    background-color: lightgreen;
  }
`

export const LoginBtn = styled(Link)`
  padding: 20px 40px;
  font-size: 30px;
  color: white;
  border: 1px solid lightgreen;
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;
  transition: 150ms ease-in-out;

  :hover {
    color: black;
    background-color: lightgreen;
  }
`