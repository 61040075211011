import React, {useState, useEffect} from 'react'
import ProductDisplay from '../components/ProductDisplay';
import SuccessDisplay from '../components/SuccessDisplay';
import Message from '../components/Message';
import { Navigate } from 'react-router';

const CheckoutScreen = ({ notify, user, setUser }) => {
  //userValid determines if a user has logged in
  let [userItem, setUserItem] = useState('')
  let [userValid, setUserValid] = useState(false)
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }

    //if there's a valid user id in local storage
    if (localStorage.getItem("user") !== null) {
      let userItem = window.localStorage.getItem("user")
      setUserItem(userItem)
      setUserValid(true)
    }
      // if user id exists w/in localStorage AND the user object is empty
      // make api call and get user 
      // tag on token to user 
  }, [sessionId]);

  if (!success && message === '') {
    return <ProductDisplay setUser={setUser} userValid={userValid}/>;
  } else if (success && sessionId !== '') {
    return <SuccessDisplay setUser={setUser} userValid={userValid} sessionId={sessionId} notify={notify}/>;
  } else {
    return <ProductDisplay userValid={userValid}/>
  }
}

export default CheckoutScreen