import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  min-height: 90vh;
  background: linear-gradient(black, #202020);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  color: white;
`
