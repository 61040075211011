import React from 'react';
import { UserVideoContainer } from '../styles/components/VideoComponent.styles';
import { Link } from 'react-router-dom'

const UserLessonVideo = ({ lesson, user }) => {
  return (
    <div>
      {user.subscribed === 'active' || lesson.freeLesson === true ? 
      <iframe src={lesson.video} style={{'width':'100%','height':'550px','left':'0','top':'0', "border":"none"}} allowFullScreen referrerPolicy='no-referrer-when-downgrade' title='Video Player'></iframe> : 
      <div style={{'width':'100%','height':'550px','left':'0','top':'0', "border":"none", "display": "flex", "alignItems":"center", "justifyContent": "center","color": "white"}}>
        <h3><Link to='/checkout' style={{"color": "lightgreen", "marginRight": "10px"}}>subscribe</Link>to see lesson</h3>
      </div>
      }
    </div>
  )
}

export default UserLessonVideo