import styled from "styled-components";

export const Container = styled.div`
  min-height: 90vh;
  width: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: left;
  background: linear-gradient(black, #202020);
  color: white;
`

export const InfoContainer = styled.div`
  width: 50%;
  min-height: 90vh;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #303030;
  padding: 0px 20px 20px 20px;
  border-radius: 10px;

  @media (max-width: 700px) {
    width: 90%;
  }

`

export const SmallContainer = styled.div`
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
`

export const H2 = styled.h2`
  color: lightgreen;
  margin-bottom: 5px;
`

export const UL = styled.ul`
  list-style-type: none;
`