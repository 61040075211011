import React, { useState } from 'react'
import { EditModalContainer, ModalFormContainer, ModalForm, ModalHeader, ModalCloseBtn, Input, Btn } from '../styles/components/EditLessonModal.styles';
import { updateALesson } from '../api/lesson';

const EditLessonModal = ({ user, ActiveEditLessonModal, showLessonEditModal, setLesson, lesson }) => {
  console.log("lesson:", lesson)

  const [title, setTitle] = useState(lesson.title);
  const [frontendRepo, setFrontendRepo] = useState(lesson.frontendRepo);
  const [backendRepo, setBackendRepo] = useState(lesson.backendRepo);
  const [goal, setGoal] = useState(lesson.goal);
  const [video, setVideo] = useState(lesson.video);
  const [freeLesson, setFreeLesson] = useState(lesson.freeLesson);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let data = {
      title: title,
      frontendRepo: frontendRepo, 
      backendRepo: backendRepo,
      goal: goal, 
      video: video,
      freeLesson: freeLesson
    }
    
    let response = await updateALesson(data, user, lesson._id)
    setLesson(response.data.newLesson)
    ActiveEditLessonModal()
  }

  return (
    <EditModalContainer showLessonEditModal={showLessonEditModal}>
      <ModalFormContainer>
        <ModalHeader>
          <h3>Edit Lesson</h3>
          <ModalCloseBtn onClick={() => ActiveEditLessonModal()}> &#10005; </ModalCloseBtn>
        </ModalHeader>
        <ModalForm onSubmit={handleSubmit}>
          <Input 
            defaultValue={title} 
            type="text" 
            placeholder={lesson.title} 
            onChange={(e) => {setTitle(e.target.value)}}
          />
          <Input 
            defaultValue={frontendRepo} 
            type="text" 
            placeholder={"frontend repo - " + lesson.frontendRepo} 
            onChange={(e) => {setFrontendRepo(e.target.value)}}
          />
          <Input 
            defaultValue={backendRepo} 
            type="text" 
            placeholder={"backend repo - " + lesson.backendRepo} 
            onChange={(e) => {setBackendRepo(e.target.value)}}
          />
          <Input 
            defaultValue={goal} 
            type="textarea" rows="5" 
            placeholder={lesson.goal} 
            onChange={(e) => {setGoal(e.target.value)}}
          />
          <Input 
            defaultValue={video} 
            type="text" 
            placeholder={lesson.video} 
            onChange={(e) => {setVideo(e.target.value)}}
          />
          <fieldset>
            <legend>Is lesson free:</legend>
            <div>
              <input 
                type="radio" 
                id="contactChoice1" 
                name="contact" 
                value="true" 
                onChange={(e) => {setFreeLesson(e.target.value)}}
                checked={ freeLesson === true }
              />
              <label for="contactChoice1">True</label>

              <input
                type="radio" 
                id="contactChoice2" 
                name="contact" 
                value="false" 
                onChange={(e) => {setFreeLesson(e.target.value)}}
                checked={ freeLesson === false }
              />
              <label for="contactChoice2">False</label>
            </div>
          </fieldset>
          <Btn type="submit">Submit</Btn>
        </ModalForm>
      </ModalFormContainer>
    </EditModalContainer>
  )
}
export default EditLessonModal