import React, { useState, useEffect } from 'react';
import { Container, Hero, CardContainer, Card, CardBtn, CardImg, CardInfoContainer, CardH2, InfoContainer, InfoHeader, InfoIconContainer, IconContainer, FirstCourseWriting, SpecialSpan, CreateAccountLink, UnorderedList, ListItem, SeeCourseLink, LeadContainer, LeadLabel, LeadInput, LeadForm, LeadBtn } from '../styles/screens/LandingScreen.styles';
import { getAllCoursesPublic } from '../api/course'
import { createLead } from '../api/lead';

const Landing = () => {
  const [courses, setCourses] = useState([])
  const [leadEmail, setLeadEmail] = useState('')
  const [emailLabel, setEmailLabel] = useState('Want to get in touch?')
  
  useEffect(() => {
    const fetchCourses = async () => {
      const response = await getAllCoursesPublic()
      setCourses(response.data.courses)
    }

    fetchCourses()
  }, [])

  const handleLeadSubmit = async (e) => {
    e.preventDefault();

    let data = {
      leadEmail: leadEmail 
    }

    try {
      let response = await createLead(data)
      
      if (response.statusText === 'OK') {
        setEmailLabel('Thank you for reaching out!')
        setLeadEmail('')
      }

    } catch(error) {
      console.log(error)
    }

  }

  return (
    <Container>
      <Hero>
        <div>
          <span>learn</span>
          <span>web</span>
          <span>development</span>
          <span>remotely</span>
          <SeeCourseLink to={'/course/637d70c1a49a3dc0267d7170'}>click to see the course</SeeCourseLink>
        </div>
      </Hero>
      <InfoContainer>
        <InfoHeader>
          <h1>Affordable Coding Bootcamp Experience</h1>
          <h3>Learn by building real projects</h3>
          <h3>Become employed by building real projects</h3>
        </InfoHeader>
        <InfoIconContainer>
          <div>
            <IconContainer>
              <h1><SpecialSpan>Interact</SpecialSpan> with other students</h1>
              <img src={require("../assets/chat.gif")} />
            </IconContainer>
            <IconContainer>
              <h1><SpecialSpan>Learn</SpecialSpan> at your pace</h1>
              <img src={require("../assets/learn.gif")} />
            </IconContainer>
          </div>
          <div>
            <IconContainer>
              <h1><SpecialSpan>Ask</SpecialSpan> questions in real-time</h1>
              <img src={require("../assets/help.gif")} />
            </IconContainer>
            <IconContainer>
              <h1><SpecialSpan>$20</SpecialSpan> per month</h1>
              <img src={require("../assets/cash.gif")} />
            </IconContainer>
          </div>
        </InfoIconContainer>
        {/* <CreateAccountLink to="/register">Sign Up Now !</CreateAccountLink> */}
        <LeadContainer>
          <LeadLabel>{emailLabel}</LeadLabel>
          <LeadForm onSubmit={handleLeadSubmit}>
            <LeadInput 
              type="email"
              placeholder='enter email'
              onChange={(e) => setLeadEmail(e.target.value)}
              required
            />
            <LeadBtn type="submit">Submit</LeadBtn>
          </LeadForm>
        </LeadContainer>
        <CardContainer>
          {courses.map((course) => (
            <Card key={course._id}>
              <FirstCourseWriting>
                <h2>our first course!</h2>
                <img src={require("../assets/yellowarrow.png")} />
              </FirstCourseWriting>
              <CardH2>{course.title}</CardH2>
              <CardImg src={course.thumbnail}/>
              <CardInfoContainer>
                <a href="https://gallacticcat.com/" target="_blank" rel="noreferrer">{course.m}</a>
                <p>This fullstack eComm course incorporates a Stripe integration to process payments in USD. Implements useContext React hook to save users cart without login to streamline checkout.</p>
                <UnorderedList>
                  <ListItem>MongoDB</ListItem>
                  <ListItem>Express</ListItem>
                  <ListItem>React</ListItem>
                  <ListItem>Node</ListItem>
                </UnorderedList>
                <CardBtn to={`/coursehome/${course._id}`}>course   details</CardBtn>
              </CardInfoContainer>
            </Card>
          ))}
      </CardContainer>
      </InfoContainer>
    </Container>
  )
}

export default Landing