import React, { useEffect, useState } from 'react';
import { getAllUsers } from '../api/users';

import { Container, UserContainer, User, H1 } from '../styles/screens/AdminUserScreen.styles';

const AdminUserScreen = ({ user }) => {
  const [users, setUsers] = useState([])

  useEffect(() => {
    const fetchUsers = async (user) => {
      try {
        const response = await getAllUsers(user)
        console.log(response)
        setUsers(response.data.users)
      } catch(e) {
        console.log(e)
      }
    }

    fetchUsers(user)
  }, [])

  return (
    <Container>
      <UserContainer>
        <H1 style={{'color': 'lightGreen'}}>Users</H1>
        {users.map((user) => (
          <User key={user._id}>
            <h1>{user.email}</h1>
            <h3>{`subscribed: ${user.subscribed}`}</h3>
          </User>
        ))}
      </UserContainer>
    </Container>
  )
}

export default AdminUserScreen