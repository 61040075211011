import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { getUser } from '../api/auth';
import { subSuccess } from '../api/stripe';
import { Container } from '../styles/components/SuccessDisplay.styles'

const SuccessDisplay = ({ setUser, userValid, sessionId, notify}) => {
  const navigate = useNavigate()

  useEffect(() => {
    let id = window.localStorage.getItem("user")
    
    const fetchUser = async (id) => {
      try {
        const query = new URLSearchParams(window.location.search);

        console.log(query)

        let response = await getUser(id)
        const user = response.data.user

        console.log("user after success.", user)
        setUser(user)
        notify('subscription successful')
        navigate(`/profile`)
      } catch(error) {
        console.log(error)
      }
    }

    fetchUser(id)
  }, [])

  return (
    <Container>
      <h1>Subscription Successful</h1>
      <p>redirecting to your profile screen</p>
      <p style={{"color": "lightgreen"}}>loading...</p>
    </Container>
  );
}

export default SuccessDisplay