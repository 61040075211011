import React, { useState, useEffect } from 'react'; 
import { getAllPublishedCourses } from '../api/course';
import { Navigate } from 'react-router';
import { HomeScreenContainer, CoursesContainer } from '../styles/screens/HomeScreen.styles';
import Course from '../components/Course';

const Home = ({ user }) => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchCourses = async (user) => {
      let response = await getAllPublishedCourses(user)
      setCourses(response.data.courses)
    }

    fetchCourses(user)
  }, []);

  if (Object.keys(user).length === 0) {
    return <Navigate to='/'/>
  } 

  return (
    <HomeScreenContainer>
        <h1>Courses:</h1>
        <CoursesContainer>
          {courses.length > 0 ? courses.map((course, index) => (
            <Course id={course._id} course={course} key={index}/>
          )) : 'loading...'}
        </CoursesContainer>
    </HomeScreenContainer>
  )
}

export default Home