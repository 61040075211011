import React, {useEffect} from 'react'
import { manageSub } from '../api/stripe'
import { getUser } from '../api/auth'
import { Container, InfoContainer, Btn, H1, BtnLink } from '../styles/screens/ProfileScreen.styles'

const ProfileScreen = ({ user, setUser }) => {

  useEffect(() => {
    let userId = window.localStorage.getItem("user")
    
    const fetchUser = async (userId) => {
      try {
        const query = new URLSearchParams(window.location.search);

        let response = await getUser(userId)
        console.log(response)
        console.log("user.subscribed value: ", response.data.user.subscribed)
        setUser(response.data.user)
        
      } catch(error) {
        console.log(error)
      }
    }

    fetchUser(userId)
  }, [])


  const handleManageSub = async (id) => {
    const url = 'https://billing.stripe.com/p/login/4gw6ph4LGaSE4s8eUU'
    try {
      // let response = await manageSub(id)
      // console.log(response)
      window.location.replace(url)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Container>
      <InfoContainer>
        <H1>Profile</H1>
        {Object.keys(user).length > 0 ? <div>
        <h4>{user.email}</h4>
        {user.subscribed === "active" ? <h4>subscription active</h4> : <h4>subscription inactive</h4>}
        </div> : null}
        {user.subscribed === 'active' ? <Btn onClick={() => {handleManageSub(user.stripeCustomerID)}}>manage subscription</Btn> : <BtnLink to='/checkout'>Subscribe</BtnLink>}
      </InfoContainer>
    </Container>
  )
}

export default ProfileScreen