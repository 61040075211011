import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  background: linear-gradient(black, #202020);
  min-height: 90vh;
  display: flex;
  flex-direction: column;
`

export const UserContainer = styled.div`
  width: 90%;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: transparent;
`

export const User = styled.div`
  background-color: transparent;
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  margin-bottom: 10px;
  border: 1px solid gray;
`

export const H1 = styled.h1`
  color: lightGreen;
  margin-bottom: 10px;
`