import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import { getASingleCoursePublic } from '../api/course';
import { Screen, BackBtn, Container, InfoContainer, BuyBtn, Description, Title } from '../styles/screens/CourseHomeScreen.styles'

const CourseHomeScreen = ({ user }) => {
  let { id } = useParams();
  const navigate = useNavigate()

  const [course, setCourse] = useState({});

  useEffect(() => {
    const fetchCourse = async (id) => {
      let response = await getASingleCoursePublic(id);
      setCourse(response.data.course);
    }

     fetchCourse(id);
  }, []);

  const handleNavigate = (id) => {
    navigate(`/course/${id}`)
  }

  return (
    <Screen>
      <Container>
        {Object.keys(user).length > 0 ? <BackBtn to="/home">Back</BackBtn> : <BackBtn to="/">Back</BackBtn>}
        <iframe src={course.video} style={{'width':'100%','height':'550px','left':'0','top':'0', 
"border":"none" }} allowFullScreen referrerPolicy='no-referrer-when-downgrade' title='Video Player'></iframe>
        <InfoContainer>
          <Title styles={{color: "white"}}>{course.title}</Title>
          <Description>{course.about}</Description>
          <BuyBtn onClick={() => handleNavigate(id)}>to course</BuyBtn>
        </InfoContainer>
      </Container>
    </Screen>
  )
}

export default CourseHomeScreen