import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getASingleLesson } from '../api/lesson';
import { Container, BackBtn, BtnContainer, InfoContainer, InfoContainerHeader, Btn, LessonInfoContainer, LessonSpan } from '../styles/screens/AdminCourseScreen.styles';
import AdminVideo from '../components/AdminVideo';
import EditLessonModal from '../components/EditLessonModal';

const AdminLessonScreen = ({ user }) => {
  const { id } = useParams();
  const [lesson, setLesson] = useState({});
  const [showLessonEditModal, setShowLessonEditModal] = useState(false)
  const [hasVideo, setHasVideo] = useState(false)
  
  useEffect(() => {
    const fetchLesson = async () => {
      let response = await getASingleLesson(user, id)
      setLesson(response.data.lesson)

      if (response.data.lesson.video === "no video") {
        setHasVideo(false)
      } else {
        setHasVideo(true)
      }
    } 
    
    fetchLesson()
  }, [])

  const ActiveEditLessonModal = () => {
    setShowLessonEditModal(!showLessonEditModal)
  }
  return (
    <>
      <EditLessonModal user={user} ActiveEditLessonModal={ActiveEditLessonModal} showLessonEditModal={showLessonEditModal} lesson={lesson} setLesson={setLesson}/>
      <Container>
        <InfoContainer>
          <BackBtn to={`/admin/section/${lesson.section_id}`}>Back</BackBtn>
          <InfoContainerHeader>
            <h2>Lesson Info:</h2>
            <BtnContainer>
              <Btn onClick={() => ActiveEditLessonModal()}>edit</Btn>
            </BtnContainer>
          </InfoContainerHeader>
        </InfoContainer>
        <InfoContainer>
          { hasVideo ? (<AdminVideo video={lesson.video} />) : null }
        </InfoContainer>
        <LessonInfoContainer>
          <p><LessonSpan>TITLE: </LessonSpan>{lesson.title}</p>
          <p><LessonSpan>VIDEO LINK: </LessonSpan>{lesson.video}</p>
          <p><LessonSpan>FRONTEND REPO: </LessonSpan>{lesson.frontendRepo}</p>
          <p><LessonSpan>BACKEND REPO: </LessonSpan>{lesson.backendRepo}</p>
          <p><LessonSpan>GOAL: </LessonSpan>{lesson.goal}</p>
          <p><LessonSpan>FREE LESSON: </LessonSpan>{lesson.freeLesson ? lesson.freeLesson.toString() : 'no info'}</p>
        </LessonInfoContainer>
      </Container>
    </>
  )
}

export default AdminLessonScreen