import styled from "styled-components";
import { Link } from 'react-router-dom'

export const Container = styled.div`
  min-height: 90vh;
  margin: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  justify-content: center;
  background: linear-gradient(black, #202020);
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px; 
  color: white;
  min-height: 300px;
  min-width: 300px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
`

export const H1 = styled.h1`
  margin-bottom: 20px;
  text-align: center;
`

export const Btn = styled.button`
  padding: 10px 20px;
  border: 1px solid lightgreen;
  border-radius: 5px;
  background-color: transparent;
  color: lightgreen;
  cursor: pointer;
  margin-top: auto;
  transition: 150ms ease-in-out;
  font-size: 18px;

  :hover {
    background-color: lightgreen;
    color: black;
  }
`

export const BtnLink = styled(Link)`
  padding: 10px 20px;
  border: 1px solid lightgreen;
  border-radius: 5px;
  background-color: transparent;
  color: lightgreen;
  cursor: pointer;
  margin-top: auto;
  transition: 150ms ease-in-out;
  font-size: 18px;
  text-decoration: none;
  text-align: center;

  :hover {
    background-color: lightgreen;
    color: black;
  }
`