import React from 'react'
import { Container, InfoContainer, UL, SmallContainer, H2 } from '../styles/screens/OnePager.styles'

const OnePager = () => {
  return (
    <Container>
      <InfoContainer>
        <SmallContainer>
          <H2>Inspiration:</H2>
          <p>In 2021 I attended a fullstack, immersive bootcamp. I switched careers successfully from technology sales to fullstack web development. In doing so I noticed the major problem facing individuals like myself who are trying to switch careers without a degree is that we have little to no professional experience. Other developers in the talent pool either have professional experience or they have a degree from an accredited academic institution.</p>
          <br />
          <p>To overcome this and become an ideal candidate, a bootcamp developer must rely on a very strong personal portfolio. MERNguide will be a place where developers can learn how to build full stack web apps while simultaneously being able to ask questions.</p>
        </SmallContainer>
        <SmallContainer>
          <H2>The Goal:</H2>
          <p>Guide aspiring developers to build foundational skills to meet minimal employment requirements.</p>
        </SmallContainer>
        <SmallContainer>
          <H2>The Problem:</H2>
          <UL>
            <li>Competitive roles</li>
            <li>Market Saturation with talent</li>
            <li>Hesitancy to invest in junior talent</li>
            <li>Too many different things to learn</li>
            <li>Display experience as a junior developer to stand out</li>
            <li>Cost of Education</li>
          </UL>
        </SmallContainer>
        <SmallContainer>
          <H2>Market Size:</H2>
          <p>The global coding boot camp market was valued at US$1.36 billion in 2021</p>
          <p>The market is predicted to grow to US$3.66 billion in 2027</p>
          <p>The median boot camp tuition in 2020 was $13,500 USD, with an average cost of $13,579 USD.</p>
          <p>The global coding boot camp market is determined to grow at a CAGR of 18-20% over the forecasted period of 2022-2027.</p>
          <p>This is a fragmented market with many players.</p>
        </SmallContainer>
        <SmallContainer>
          <H2>The Business Model:</H2>
          <p>MERNguide will have two business models under one overall strategy.</p>
          <p>The first model is B2B where mernguide will partner with existing market players in the boot camp market. We can offer bootcamps a post course guide to build high end projects centered around the concepts they learned within their initial bootcamp and course.</p>
          <p>Users will sign up for a monthly subscription of $40 a month.</p>
          <p>Each partnership will have a defined coupon code that will be used by students upon signup</p>
          <p>With this sign up code, the bootcamp will receive a portion of a user's monthly subscription without needing additional resources or effort.</p>
          <br />
          <p>The second business model is a B2C model</p>
          <p>mernguide will product high end video and blog content to drive published to all major social medias. Driving organic traffic to the site. This will offer independent, aspiring developers a path to success at a minimal cost.</p> 
        </SmallContainer>
        <SmallContainer>
          <H2>Competition:</H2>
          <p>There are plenty of online guides out there from Youtube to Udemy. The issues I have personally experienced with them is that some concepts are abstracted. As a developer I want to know how everything can be done.</p>
        </SmallContainer>
        <SmallContainer>
          <H2>Product Capabilities:</H2>
          <p>There are two main capabilities. The first is, strong project driven curriculums. The second is, each course has a corresponding Discord channel where users can discuss concepts and ask questions directly to the course creator or to one another.</p>
        </SmallContainer>
        <SmallContainer>
          <H2>Go to market strategy:</H2>
          <p>Make content on common dev concepts and direct viewers to the site for more tips.</p>
          <br />
          <p>Reach out to bootcamps to see if this is something that could be useful within their current curriculum.</p>
        </SmallContainer>
      </InfoContainer>
    </Container>
  )
}

export default OnePager