import axios from 'axios'
import apiUrl from '../apiConfig'

export const checkout = (priceValue) => {
  return axios.post(apiUrl + "/create-checkout-session", 
    { lookup_key: priceValue }, {
    'Content-Type': 'application/json'
  })
}

export const manageSub = (id) => {
  return axios.post(apiUrl + '/create-portal-session', {customer_id: id}, {
    'Content-Type': 'application/json'
  })
}

export const subSuccess = (user, sessionId) => {
  const body = { 
    sessionId: sessionId
  }
  
  return axios.patch(apiUrl + `/user/${user._id}/sub_success`, body, {
    headers: {
      Authorization: `Bearer ${user.token}`
    }
  })
}