import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { signOut } from '../api/auth'

const SignOut = ({ user, clearUser, notify }) => {
  const [shouldNavigate, setShouldNavigate] = useState(false)

  useEffect(() => {
    const performSignOut = async (user) => {
      try {
        let response = await signOut(user)

        if (response.status === 204) {
          clearUser()
          window.localStorage.removeItem("user")
          window.localStorage.removeItem("token")
          setShouldNavigate(true)     
        }
      } catch (e) {
        notify('something went wrong', 'danger')
      }
    }

    performSignOut(user)
  }, [])
  
  if (!user) {
    return <Navigate to="/"/>
  }

  if (shouldNavigate) {
    notify("signout successful")
    return <Navigate to='/'/>
  }
}

export default SignOut 